<template>
  <div class="wrapper">
    <div class="content">
      <div class="left-photo" v-if="showPhoto">
        <img class="left-photo-img" :src="userPhoto? userPhoto: require('../../../assets/img/product/user.png')" alt=""/>
      </div>
      <div class="content-left">
        <div class="class" v-if="showClass">
          <p class="class-item">{{$t('home.keyword') }}:</p>
          <p class="class-item class-name">{{shopData.key_words}}</p>

        </div>
        <div class="title" v-if="showInfoTitle">
          Baoke dream's picture house
        </div>
        <div class="shop-info">
          <p class="shop-about">{{$t('home.about') }}:</p>
<!--      <p class="shop-about-text"></p>-->
          <div  class="shop-about-text" v-html="shopData.about"> </div>

        </div>
      </div>
      <div class="content-right">
        <p class="Designer">{{$t('home.shopname') }}:</p>
        <div class="user-info">
          <img class="user-img" :src="shopData.shopLogo? shopData.shopLogo: require('../../../assets/img/product/user.png')" alt=""/>
          <p class="user-name">{{ designName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { get } from '@/utils/request'

export default {
  name: 'shopHeader',
  props: {
    showClass: {
      type: Boolean,
      default: false
    },
    showInfoTitle: {
      type: Boolean,
      default: false
    },
    showPhoto: {
      type: Boolean,
      default: false
    },
    designName: {
      type: String,
      default: ''
    },
    userPhoto: {
      type: String,
      default: ''
    },
    shopData: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {}
}
</script>

<style scoped lang="less">
.wrapper {
  width: 100%;
  background: #f6f6f7;
  .content {
    max-width: 1590px;
    margin: 0 auto;
    display: flex;
    .left-photo {
      margin-right: 20px;
      .left-photo-img {
        width: 324px;
        height: 236px;
        display: block;
      }
    }
    .content-left {
      flex: 1;
      margin: 20px 0;
      border-right: 1px solid rgba(33, 33, 33, 0.2);

      .class {
        display: flex;
        .class-item {
          color: #777;
          font-size: 16px;
          font-weight: 400;
          margin-right: 22px;
        }
        .class-name {
          font-weight: 400;
          font-size: 16px;
          color: #1baf92;
          cursor: pointer;
        }
      }
      .shop-info {
        display: flex;
        flex-direction: column;
        max-width: 755px;
        margin-right: 384px;
        .shop-about {
          font-size: 14px;
          font-weight: 400;
          margin-top: 30px;
        }
        .shop-about-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          margin-top: 6px;
          color: #777;
        }
      }
      .title {
        color: #242626;
        font-weight: 500;
        font-size: 24px;
      }
    }
    .content-right {
      margin: 20px 0;
      padding-top: 5px;
      padding-bottom: 14px;
      margin-left: 40px;
      .Designer {
        font-size: 16px;
        font-weight: 500;
        color: #242626;
      }
      .user-info {
        display: flex;
        align-items: center;
        margin-top: 14px;
        .user-img {
          width: 90px;
          height: 90px;
        }
        .user-name {
          font-size: 24px;
          font-weight: 500;
          color: #1baf92;
          margin-left: 20px;
        }
      }
    }
  }
}
.mt-0 {
  margin-top: 0 !important;
}
</style>
