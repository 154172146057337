<template>
  <div class="gift-content wrapper-1590 mt-40">
    <div>
      <Tree
        @searchAgain="classification"
        :listCount="total"
        @typeselection="typeselection"
      />
    </div>
    <div class="gift-content__pro">
      <div class="gift-pro__boxblank" v-if="showList.length == 0">
        <img src="../../pages/gifts/isblank.png" alt="" class="isblankimg" />
        <p class="isblanktest">{{ $t('shopcart.notemporarily') }}</p>
      </div>
      <div
        class="gift-pro__box"
        v-if="showList.length > 0 && $i18n.locale === 'en'"
      >
        <Product
          v-for="(item, index) in showList"
          :id="Number(item.id)"
          :img="item.product_pic"
          :name="item.product_name_en"
          :price="item.price_range_low"
          :summary="item.product_summary_en"
          :key="index"
          :guid="item.guid"
          :specColorList="item.specColor"
          :productClass="item.product_class"
          :collection_guid="item.collection_guid"
          :is-aside="item === 5"
          :isBlank="showList.length"
        />
        <!-- <Product :showList="showList" /> -->
      </div>
      <div
        class="gift-pro__box"
        v-if="showList.length > 0 && $i18n.locale === 'zh'"
      >
        <Product
          v-for="(item, index) in showList"
          :id="Number(item.id)"
          :img="item.product_pic"
          :name="item.product_name"
          :price="item.price_range_low"
          :summary="item.product_summary"
          :key="index"
          :guid="item.guid"
          :specColorList="item.specColor"
          :productClass="item.product_class"
          :collection_guid="item.collection_guid"
          :is-aside="item === 5"
          :isBlank="showList.length"
        />
        <!-- <Product :showList="showList" /> -->
      </div>
      <div class="gift-pro__pag">
        <el-pagination
          layout="prev, pager, next"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import Tree from '../../pages/gifts/components/tree.vue'
import Product from '../../pages/gifts/components/product.vue'
import { mapState, mapMutations } from 'vuex'
import { post } from '@/utils/request'
export default {
  components: {
    Product,
    Tree
  },

  data() {
    return {
      total: 0,
      pageSize: 12,
      showList: [],
      params: {
        callParams: 'klyx',
        pageSize: 12,
        product_class: '',
        shop_id:this.$router.currentRoute.query.id,
        /*region: sessionStorage.getItem('region') || '142'*/
        shopSiteId:sessionStorage.getItem('shopSiteId') || '1'
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(val) {
        let product_type_id = this.$route.query.product_type_id || ''
        this.updateCategory(product_type_id)
        if (product_type_id) {
          this.params.product_type_id = product_type_id
        }
        // 处理别的页面携带过来的参数

        let params = {
          ...this.params,
          ...this.$route.query,
          pageIndex: 0
        }
        this.params = params
        this.getProductLsit()
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    let product_type_id = this.$route.query.product_type_id || ''
    this.updateCategory(product_type_id)
    if (product_type_id) {
      this.params.product_type_id = product_type_id
    }
    // 处理别的页面携带过来的参数

    let params = {
      ...this.params,
      ...this.$route.query,
      pageIndex: 0,
      shop_id:this.$router.currentRoute.query.id
    }
    this.params = params
    this.getProductLsit()
  },
  methods: {
    ...mapMutations(['updateCategory']),
    // 分页
    handleCurrentChange(val) {
      let params = {
        ...this.params,
        pageIndex: val - 1
      }
      this.params = params
      this.getProductLsit()
    },

    // 获取列表数据
    getProductLsit() {
      let params = {
        ...this.params,
        /*region: sessionStorage.getItem('region') || '142'*/
        shopSiteId:sessionStorage.getItem('shopSiteId') || '1'
      }
      post(
        '/klyx-server/product/queryProductList',
        { params: params },
        true
      ).then((res) => {
        this.total = res.totalCount
        this.showList = res.data
      })
    },
    // 选择订制品
    typeselection(msg) {
      let product_type_id = this.$route.query.product_type_id || ''
      this.updateCategory(product_type_id)
      if (product_type_id) {
        this.params.product_type_id = product_type_id
      }
      if (msg) {
        this.params.product_class = 1
      } else {
        this.params.product_class = ''
      }
      this.$router.push({
        query: {
          ...this.$route.query,
          checkedCustom: msg
        }
      })
      this.getProductLsit()
    },
    classification(id) {
      // let params = {
      //   ...this.params,
      //   product_type_id: id,
      //   pageIndex: 0
      // }
      // this.params = params
      // this.getProductLsit()
      this.$router.push({
        query: {
          ...this.$route.query,
          product_type_id: id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.gift-content {
  display: flex;
}

.gift-content__pro {
  flex: 1;
}
.gift-pro__boxblank {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 1262px;
  height: 663px;
}
.isblankimg {
  width: 300px;
  height: 292px;
  opacity: 1;
}
.isblanktest {
  width: auto;
  height: 24px;
  font-size: 24px;
  font-family: Montserrat-Bold;
  font-weight: 600;
  line-height: 24px;
  margin-top: 35px;
  color: #1baf92;
  text-transform: uppercase;
  opacity: 1;
}
.gift-pro__pag {
  text-align: center;
  margin: 50px 0 70px;
}
.gift-pro__box {
  display: grid;
  grid-template-columns: repeat(4, auto);
}
.gift-content/deep/.gift-content__pro {
  .el-pagination {
    .btn-prev {
      position: relative;
      border: none;
      background-color: transparent;

      i {
        display: none;
      }

      &:after {
        position: absolute;
        top: 0;
        left: -100%;
        // content: 'Previous';
        content: '上一页';
        line-height: 36px;
        font-size: 16px;
        font-weight: 500;

        color: #1baf92;
      }
    }

    .el-pager {
      .number {
        margin: 0 8px;
        width: 33px;
        height: 36px;
        border: 1px solid #97cbc1;
        border-radius: 8px;
        color: #97cbc1;

        line-height: 36px;
        font-size: 14px;

        &.active {
          color: #fff;
          background-color: #1baf92;
        }
      }

      .el-icon.more {
        height: 36px;
        color: #97cbc1;
        line-height: 36px;
      }
    }

    .btn-next {
      position: relative;
      border: none;
      background-color: transparent;

      i {
        display: none;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 17px;
        // content: 'Next';
        content: '下一页';
        line-height: 36px;
        font-weight: 500;
        font-size: 16px;
        color: #1baf92;
      }
    }

    .btn-prev[disabled],
    btn-next[disabled] {
      opacity: 0.5;
    }
  }
}
</style>
