var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gift-content wrapper-1590 mt-40" }, [
    _c(
      "div",
      [
        _c("Tree", {
          attrs: { listCount: _vm.total },
          on: {
            searchAgain: _vm.classification,
            typeselection: _vm.typeselection,
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "gift-content__pro" }, [
      _vm.showList.length == 0
        ? _c("div", { staticClass: "gift-pro__boxblank" }, [
            _c("img", {
              staticClass: "isblankimg",
              attrs: { src: require("../../pages/gifts/isblank.png"), alt: "" },
            }),
            _c("p", { staticClass: "isblanktest" }, [
              _vm._v(_vm._s(_vm.$t("shopcart.notemporarily"))),
            ]),
          ])
        : _vm._e(),
      _vm.showList.length > 0 && _vm.$i18n.locale === "en"
        ? _c(
            "div",
            { staticClass: "gift-pro__box" },
            _vm._l(_vm.showList, function (item, index) {
              return _c("Product", {
                key: index,
                attrs: {
                  id: Number(item.id),
                  img: item.product_pic,
                  name: item.product_name_en,
                  price: item.price_range_low,
                  summary: item.product_summary_en,
                  guid: item.guid,
                  specColorList: item.specColor,
                  productClass: item.product_class,
                  collection_guid: item.collection_guid,
                  "is-aside": item === 5,
                  isBlank: _vm.showList.length,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.showList.length > 0 && _vm.$i18n.locale === "zh"
        ? _c(
            "div",
            { staticClass: "gift-pro__box" },
            _vm._l(_vm.showList, function (item, index) {
              return _c("Product", {
                key: index,
                attrs: {
                  id: Number(item.id),
                  img: item.product_pic,
                  name: item.product_name,
                  price: item.price_range_low,
                  summary: item.product_summary,
                  guid: item.guid,
                  specColorList: item.specColor,
                  productClass: item.product_class,
                  collection_guid: item.collection_guid,
                  "is-aside": item === 5,
                  isBlank: _vm.showList.length,
                },
              })
            }),
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "gift-pro__pag" },
        [
          _c("el-pagination", {
            attrs: {
              layout: "prev, pager, next",
              "page-size": _vm.pageSize,
              total: _vm.total,
            },
            on: { "current-change": _vm.handleCurrentChange },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }