var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper" }, [
    _c("div", { staticClass: "content" }, [
      _vm.showPhoto
        ? _c("div", { staticClass: "left-photo" }, [
            _c("img", {
              staticClass: "left-photo-img",
              attrs: {
                src: _vm.userPhoto
                  ? _vm.userPhoto
                  : require("../../../assets/img/product/user.png"),
                alt: "",
              },
            }),
          ])
        : _vm._e(),
      _c("div", { staticClass: "content-left" }, [
        _vm.showClass
          ? _c("div", { staticClass: "class" }, [
              _c("p", { staticClass: "class-item" }, [
                _vm._v(_vm._s(_vm.$t("home.keyword")) + ":"),
              ]),
              _c("p", { staticClass: "class-item class-name" }, [
                _vm._v(_vm._s(_vm.shopData.key_words)),
              ]),
            ])
          : _vm._e(),
        _vm.showInfoTitle
          ? _c("div", { staticClass: "title" }, [
              _vm._v(" Baoke dream's picture house "),
            ])
          : _vm._e(),
        _c("div", { staticClass: "shop-info" }, [
          _c("p", { staticClass: "shop-about" }, [
            _vm._v(_vm._s(_vm.$t("home.about")) + ":"),
          ]),
          _c("div", {
            staticClass: "shop-about-text",
            domProps: { innerHTML: _vm._s(_vm.shopData.about) },
          }),
        ]),
      ]),
      _c("div", { staticClass: "content-right" }, [
        _c("p", { staticClass: "Designer" }, [
          _vm._v(_vm._s(_vm.$t("home.shopname")) + ":"),
        ]),
        _c("div", { staticClass: "user-info" }, [
          _c("img", {
            staticClass: "user-img",
            attrs: {
              src: _vm.shopData.shopLogo
                ? _vm.shopData.shopLogo
                : require("../../../assets/img/product/user.png"),
              alt: "",
            },
          }),
          _c("p", { staticClass: "user-name" }, [
            _vm._v(_vm._s(_vm.designName)),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }